<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">{{$route.name == 'trainer-trainee-to-trainer-criteria-create' ? 'Add Criteria' : 'Edit Criteria' }}</h4>
        </div>
      </div>
    </div>

    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
         
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">

              <div class="row mb-3">
                <label for="name" class="col-md-3 form-label">
                  Criteria Name
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'trainer-trainee-to-trainer-criteria-edit',
                      'trainer-trainee-to-trainer-criteria-create',
                    ])
                  "
                >
                  <input
                    type="text"
                    id="name"
                    name="name"
                    class="form-control"
                    placeholder="Criteria Name"
                    v-model="criteria.name"
                    :class="{
                      'p-invalid': v$.criteria.name.$error || errorFor('name'),
                    }"
                  />
                  <v-errors
                    :serverErrors="errorFor('name')"
                    :vuelidateErrors="{
                      errors: v$.criteria.name.$errors,
                      value: 'Criteria Name',
                    }"
                  ></v-errors>
                </div>
                <div
                  class="col-md-6"
                  v-else-if="
                    hasPermissions(['trainer-trainee-to-trainer-criteria-list'])
                  "
                >
                  <input
                    type="text"
                    id="name"
                    name="name"
                    class="form-control"
                    placeholder="Criteria Name"
                    v-model="criteria.name"
                    disabled
                  />
                </div>
              
              </div>

              <div class="row mb-3">
                <label for="lowest_score" class="col-md-3 form-label">
                  Lowest Score
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'trainer-trainee-to-trainer-criteria-edit',
                      'trainer-trainee-to-trainer-criteria-create',
                    ])
                  "
                >
                  <input
                    type="number"
                    id="lowest_score"
                    name="lowest_score"
                    class="form-control"
                    @input="lowScoreChanges($event.target.value)"
                    placeholder="Lowest Score"
                    v-model="criteria.lowest_score"
                    :class="{
                      'p-invalid':
                        v$.criteria.lowest_score.$error ||
                        errorFor('lowest_score'),
                    }"
                  />
                  <v-errors
                    :serverErrors="errorFor('lowest_score')"
                    :vuelidateErrors="{
                      errors: v$.criteria.lowest_score.$errors,
                      value: 'Lowest Score',
                    }"
                  ></v-errors>
                </div>

                <div
                  class="col-md-6"
                  v-else-if="
                    hasPermissions(['trainer-trainee-to-trainer-criteria-list'])
                  "
                >
                  <input
                    type="number"
                    id="lowest_score"
                    name="lowest_score"
                    class="form-control"
                    v-model="criteria.lowest_score"
                    disabled
                  />
                </div>
              </div>

              <div class="row mb-3">
                <label for="highest_score" class="col-md-3 form-label">
                  Highest Score
                  <span class="text-danger">*</span>
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'trainer-trainee-to-trainer-criteria-edit',
                      'trainer-trainee-to-trainer-criteria-create',
                    ])
                  "
                >
                  <input
                    type="number"
                    id="highest_score"
                    name="highest_score"
                    class="form-control"
                    @input="highScoresChanges($event.target.value)"
                    placeholder="Highest Score"
                    v-model="criteria.highest_score"
                    :class="{
                      'p-invalid':
                        v$.criteria.highest_score.$error ||
                        errorFor('highest_score'),
                    }"
                  />
                  <v-errors
                    :serverErrors="errorFor('highest_score')"
                    :vuelidateErrors="{
                      errors: v$.criteria.highest_score.$errors,
                      value: 'Highest Score',
                    }"
                  ></v-errors>
                </div>

                <div
                  class="col-md-6"
                  v-else-if="
                    hasPermissions(['trainer-trainee-to-trainer-criteria-list'])
                  "
                >
                  <input
                    type="number"
                    id="highest_score"
                    name="highest_score"
                    class="form-control"
                    v-model="criteria.highest_score"
                    disabled
                  />
                </div>
              </div>

              <div
                class="row mb-3"
                v-if="
                  currentRouteName === 'trainer-trainee-criteria-update'
                "
              >
                <label for="status" class="col-md-3 form-label">
                  Status
                  <!-- <span class="text-danger">*</span> -->
                  <!-- :disabled="
                        currentRouteName == 'trainer-update' ? false : true
                      " -->
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'trainer-trainee-to-trainer-criteria-edit',
                      'trainer-trainee-to-trainer-criteria-create',
                    ])
                  "
                >
                  <select name="status" v-model="criteria.status" class="form-control">
                    <option :value="1">Active</option>
                    <option :value="0">Inactive</option>
                  </select>
                </div>

                <div
                  class="col-md-6"
                  v-else-if="
                    hasPermissions(['trainer-trainee-to-trainer-criteria-list'])
                  "
                >
                  <select
                    name="status"
                    class="form-control"
                    disabled
                  >
                    <option :value="1">Active</option>
                    <option :value="0">Inactive</option>
                  </select>
                </div>
              </div>

              <div class="row mb-3" v-if="!criteria.status">
                <label for="remark" class="col-md-3 form-label">
                  Remark
                  <!-- <span class="text-danger">*</span> -->
                </label>
                <div
                  class="col-md-6"
                  v-if="
                    hasPermissions([
                      'trainer-trainee-to-trainer-criteria-edit',
                      'trainer-trainee-to-trainer-criteria-create',
                    ])
                  "
                >
                  <textarea
                    type="text"
                    id="remark"
                    name="remark"
                    class="form-control"
                    placeholder="Remark"
                    v-model="criteria.remark"
                    :class="{
                      'p-invalid': v$.criteria.remark.$error || errorFor('remark'),
                    }"
                  >
                  </textarea>
                  <v-errors
                    :serverErrors="errorFor('remark')"
                    :vuelidateErrors="{
                      errors: v$.criteria.remark.$errors,
                      value: 'Remark',
                    }"
                  ></v-errors>
                </div>

              </div>
              <!-- end row -->
              <div
                class="row"
                v-if="
                  hasPermissions([
                    'trainer-trainee-to-trainer-criteria-edit',
                    'trainer-trainee-to-trainer-criteria-create',
                  ])
                "
              >
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'trainer-trainee-criteria' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName ===
                        'trainer-trainee-criteria-update'
                          ? updateCriteria()
                          : createCriteria()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },

  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      criteria: {
        name: "",
        lowest_score: "",
        highest_score: "",
        status: 1,
        remark: null,
      },
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      loading: false,
      isLoading: false,
    };
  },
  validations() {
    return {
      criteria: {
        name: { required },
        lowest_score: { required },
        highest_score: { required },
        remark : this.criteria.status ? '' : {required}
      },
    };
  },
  methods: {
    lowScoreChanges(val) {
      this.criteria.lowest_score =
        val && Math.abs(val) >= 1 && Math.abs(val) <= 5 ? Math.abs(val) : null;
      return this.criteria.lowest_score;
    },
    highScoresChanges(val) {
      this.criteria.highest_score =
        val && Math.abs(val) >= 1 && Math.abs(val) <= 5 ? Math.abs(val) : null;
      return this.criteria.highest_score;
    },

    async getCriteriaById() {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v2/trainee-to-trainer-criteria/${this.$route.params.id}`
        )
        .then((response) => {
          const result = response.data.data;
        
          this.criteria.name = result.name;
          this.criteria.lowest_score = result.lowest_score;
          this.criteria.highest_score = result.highest_score;
          this.criteria.status = result.status;
          this.criteria.remark = !result.status ? result.remark : null;
        })
        .catch(() => {
          this.toast.error("Not Found Criteria!");
        });
      this.loading = false;
    },

    async createCriteria() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this.isLoading = true;
      
      await axios
        .post(
          `${this.baseUrl}admin/v2/trainee-to-trainer-criteria`,
          this.criteria
        )
        .then(() => {
          this.$router.push({ name: "trainer-trainee-criteria" });
          this.toast.success("Successfully Created Criteria!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async updateCriteria() {

      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this.isLoading = true

      axios
        .put(
          `${this.baseUrl}admin/v2/trainee-to-trainer-criteria/${this.$route.params.id}`,
          this.criteria
        )
        .then(() => {
          this.$router.push({ name: "trainer-trainee-criteria" });
          this.toast.success("Successfully Criteria Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
  },
  async created() {

    if (this.currentRouteName == "trainer-trainee-criteria-update") {
      this.getCriteriaById();
    }
  },
  computed: {

    currentRouteName() {
      return this.$route.name;
    },
    filterAssessmentCriteria() {
       if(this.criteria.level == "") {
        //
        return [];
       }else{
      return  this.assessmentCriteria.filter((val)=>val.level == this.criteria.level && val.status == 0) 
       }
        
             

    },
  },

  watch: {
    'criteria.level'(){
      this.criteria.assessment_criteria_id = "";
    }
  }
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
</style>
